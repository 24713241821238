
import Vue from 'vue'
import {
  DonationCampaign,
  DonationCampaignApi,
  DonationCampaignDonor,
  DonationCampaignDonorApi,
  DonationCampaignSupport,
  DonationCampaignSupportApi,
} from '~/models/donation-campaign'
import { DateFormat } from '~/assets/ts/enums'
import { Sermon } from '~/models/sermon'
import {
  siteBroadcasterUrl,
  siteSermonUrl,
  siteShareUrl,
  siteSpeakerUrl,
} from '~/assets/ts/utils/urls'
import { localizeDateTime } from '~/assets/ts/utils/date'
import { toCurrency } from '~/assets/ts/utils/math'
import { returnUndefinedOnError } from '~/assets/ts/utils/misc'
import ProgressBar from '~/components/_general/ProgressBar.vue'
import NavigationTab from '~/components/_general/NavigationTab.vue'
import SaModal from '~/components/_general/SaModal.vue'
import ScrollList from '~/components/_general/ScrollList.vue'
import MarkdownElement from '~/components/markdown/Element.vue'
import SpeakerImage from '~/components/_general/SpeakerImage.vue'

interface DonationDisplay {
  name: string
  amount: string
  date: string
  url: string
  id: number
  highlight: boolean
  sermon?: Sermon
}

export default Vue.extend({
  name: 'DonationCampaignElement',
  components: {
    SpeakerImage,
    MarkdownElement,
    ScrollList,
    SaModal,
    NavigationTab,
    ProgressBar,
  },
  props: {
    useTheme: {
      type: Boolean,
      default: true,
    },
    campaignID: {
      type: String,
      default: '',
    },
    modalTitle: {
      type: String,
      default: '',
    },
    modalDescription: {
      type: String,
      default: '',
    },
    showTopDonors: {
      type: Boolean,
      default: true,
    },
    showCompletionDate: {
      type: Boolean,
      default: true,
    },
    highlightDonorThreshold: {
      type: Number,
      default: 0,
    },
    accentColorClass: {
      type: String,
      default: 'text-blue',
      validator: (value: string) => {
        return value.includes('text-')
      },
    },
  },
  data() {
    return {
      supportLength: 0,
      donorLength: 0,
      donationPageSize: 25,
      donorApiPage: 1,
      supportApiPage: 1,
      supportTab: true,
      inModal: false,
      campaignData: undefined as DonationCampaignApi | undefined,
      supportData: [] as DonationCampaignSupportApi[],
      donorData: [] as DonationCampaignDonorApi[],
    }
  },
  async fetch() {
    if (!this.campaignID) return
    this.campaignData = await this.$apiClient
      .getDonationCampaign(this.campaignID)
      .catch()
  },
  computed: {
    donorTab(): boolean {
      return !this.supportTab || !this.support?.length
    },
    hasSupport(): boolean {
      return !!(this.campaign && this.campaign.totalSupport)
    },
    timeRemainingString(): string {
      if (this.campaign === undefined) return ''
      const days = this.campaign.daysRemaining
      if (days === undefined) return ''
      if (days <= 0) return this.$t('Campaign has ended').toString()
      return this.$tc('{n} Day Remaining | {n} Days Remaining', days).toString()
    },
    campaign(): DonationCampaign | undefined {
      if (!this.campaignData) return undefined
      return new DonationCampaign(this.campaignData)
    },
    donors(): DonationCampaignDonor[] | undefined {
      if (!this.donorData.length) return undefined
      const donorList = [] as DonationCampaignDonor[]
      this.donorData.forEach((donor: DonationCampaignDonorApi) => {
        donorList.push(new DonationCampaignDonor(donor))
      })
      return donorList
    },
    support(): DonationCampaignSupport[] | undefined {
      if (!this.supportData.length) return undefined
      const supportList = [] as DonationCampaignSupport[]
      this.supportData.forEach((support: DonationCampaignSupportApi) => {
        supportList.push(new DonationCampaignSupport(support))
      })
      return supportList
    },
    shortDonationList(): DonationDisplay[] {
      return this.donationList.slice(0, 4)
    },
    // this helps us re-sort the support tab so larger items don't line up weird
    sortedDonationList(): DonationDisplay[] {
      if (this.donorTab) return this.donationList
      const list = [...this.donationList]
      let gridIndex = 0
      for (let i = 0; i < list.length; i++) {
        const h = list[i].highlight
        gridIndex += h ? 2 : 1
        if (!h) continue
        const mod = gridIndex % 2 === 1
        if (!mod) continue
        this.swapDonationItems(list, i, i - 1)
      }
      return list
    },
    maxDonationListLength(): number {
      return this.supportTab ? this.supportLength : this.donorLength
    },
    donationList(): DonationDisplay[] {
      const list = [] as DonationDisplay[]
      if (this.supportTab) {
        if (this.support) {
          this.support.forEach((support) => {
            list.push(
              this.formatDonationDisplay(
                support.id,
                support.amount,
                support.donor,
                support.date
              )
            )
          })
        }
      } else if (this.donorTab) {
        if (this.donors) {
          this.donors.forEach((donor) => {
            list.push(
              this.formatDonationDisplay(
                donor.id,
                donor.totalSupport as number,
                donor
              )
            )
          })
        }
      }
      return list
    },
  },
  async mounted() {
    await this.loadDonations()
  },
  methods: {
    sermonUrl(donation: DonationDisplay): string {
      return siteShareUrl(siteSermonUrl(donation.sermon))
    },
    speakerUrl(donation: DonationDisplay): string {
      return siteShareUrl(siteSpeakerUrl(donation.sermon?.speaker))
    },
    async loadDonations() {
      if (this.supportTab || this.supportApiPage === 1) {
        const data = await this.$apiClient
          .getDonationCampaignSupport(
            this.campaignID,
            'latest',
            this.supportApiPage,
            this.donationPageSize
          )
          .catch(returnUndefinedOnError)
        if (data) {
          this.supportData = [...this.supportData, ...data.results]
          this.supportLength = data.totalCount
          this.supportApiPage += 1
        }
      }
      if ((this.showTopDonors && this.donorTab) || this.donorApiPage === 1) {
        const data = await this.$apiClient
          .getDonationCampaignDonors(
            this.campaignID,
            this.donorApiPage,
            this.donationPageSize
          )
          .catch(returnUndefinedOnError)
        if (data) {
          this.donorData = [...this.donorData, ...data.results]
          this.donorLength = data.totalCount
          this.donorApiPage += 1
        }
      }
    },
    swapDonationItems(
      array: DonationDisplay[],
      a: number,
      b: number
    ): DonationDisplay[] {
      array[a] = array.splice(b, 1, array[a])[0]
      return array
    },
    openDonorModal() {
      this.$modal.show('donorModal')
    },
    formatDonationDisplay(
      id: number,
      amount: number,
      donor: DonationCampaignDonor,
      date: Date | undefined = undefined
    ): DonationDisplay {
      const highlight =
        !!this.highlightDonorThreshold && amount >= this.highlightDonorThreshold
      return {
        name: donor.displayName,
        amount: this.currency(amount),
        id,
        url: donor.broadcaster ? siteBroadcasterUrl(donor.broadcaster) : '',
        date: date ? localizeDateTime(date, DateFormat.TinyDate) : '',
        highlight,
        sermon: donor.sermon,
      }
    },
    toggleSupportList() {
      this.supportTab = !this.supportTab
    },
    currency(value: number): string {
      return toCurrency(value, this.$i18n.locale)
    },
  },
})
