
import Vue from 'vue'
import BroadcasterDonateButton from '~/components/broadcaster/DonateButton.vue'
import { Broadcaster } from '~/models/broadcaster'
import SaIcon from '~/components/_general/SaIcon.vue'
import { BroadcasterDonationMethod } from '~/apiclient/apibroadcasters'

export default Vue.extend({
  name: 'VaultDonateButton',
  components: { SaIcon, BroadcasterDonateButton },
  computed: {
    BroadcasterDonationMethod() {
      return BroadcasterDonationMethod
    },
    legacyDonate(): boolean {
      return false
    },
    broadcaster(): Broadcaster | undefined {
      const broadcaster =
        this.$store.getters['broadcasters/broadcaster']('misc')
      return broadcaster ? new Broadcaster(broadcaster) : undefined
    },
    baseUrl(): string {
      return this.$isClient ? window.location.origin : ''
    },
  },
  methods: {
    setModal(open: boolean) {
      this.$store.commit('site/SET_MODAL_OPEN', open)
    },
  },
})
