
import Vue from 'vue'
import SaIcon from '~/components/_general/SaIcon.vue'

export default Vue.extend({
  name: 'SaModal',
  components: { SaIcon },
  props: {
    // https://euvl.github.io/vue-js-modal/Properties.html#properties-2
    name: {
      type: String,
      required: true,
    },
    width: {
      type: [String, Number],
      default: 600,
    },
    height: {
      type: [String, Number],
      default: 'auto',
    },
    closeButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      scrollbar: false,
    }
  },
  methods: {
    updateScrollbar(event: string | undefined = undefined) {
      if (event) {
        this.$emit(event)
      }
      if (!this.closeButton) return
      const button = this.$refs.closeButton as Element
      if (!button) return
      const el = button.nextElementSibling
      if (!el) return
      const scrollHeight = el.scrollHeight
      const clientHeight = el.clientHeight
      this.scrollbar = clientHeight < scrollHeight
    },
  },
})
