import { apiTimestampToJsDate, daysBetweenDates } from '~/assets/ts/utils/date'
import { Broadcaster } from '~/models/broadcaster'
import { Sermon } from '~/models/sermon'

export interface DonationCampaignApi {
  campaignID: string
  completionTimestamp?: number
  goalAmount: number
  name: string
  numberOfDonors: number
  totalSupport: number
}

export class DonationCampaign {
  id: string
  name: string
  completionDate?: Date
  goalAmount: number
  numberOfDonors: number
  totalSupport: number
  constructor(campaign: DonationCampaignApi) {
    this.id = campaign.campaignID
    this.name = campaign.name
    this.completionDate = campaign.completionTimestamp
      ? apiTimestampToJsDate(campaign.completionTimestamp)
      : undefined
    this.goalAmount = campaign.goalAmount
    this.numberOfDonors = campaign.numberOfDonors
    this.totalSupport = campaign.totalSupport
  }

  get daysRemaining(): number | undefined {
    if (!this.completionDate) return undefined
    return daysBetweenDates(new Date(), this.completionDate)
  }
}
export interface DonationCampaignDonorApi {
  broadcaster?: Record<string, any>
  displayName: string
  donorID: number
  totalSupportToDate?: number
  sermon?: Record<string, any>
}

export class DonationCampaignDonor {
  id: number
  displayName: string
  broadcaster?: Broadcaster
  totalSupport?: number
  anonymous: boolean
  sermon?: Sermon
  constructor(donor: DonationCampaignDonorApi) {
    this.id = donor.donorID
    this.displayName = donor.displayName
    this.broadcaster = donor.broadcaster
      ? new Broadcaster(donor.broadcaster)
      : undefined
    this.anonymous = !!this.broadcaster
    this.totalSupport = donor.totalSupportToDate || undefined
    this.sermon = donor.sermon ? new Sermon(donor.sermon) : undefined
  }
}

export interface DonationCampaignSupportApi {
  supportID: number
  amount: number
  campaignID: string
  donationTimestamp: number
  donor: DonationCampaignDonorApi
}

export class DonationCampaignSupport {
  id: number
  amount: number
  campaignID: string
  date: Date
  donor: DonationCampaignDonor
  constructor(support: DonationCampaignSupportApi) {
    this.id = support.supportID
    this.amount = support.amount
    this.campaignID = support.campaignID
    this.date = apiTimestampToJsDate(support.donationTimestamp)
    this.donor = new DonationCampaignDonor(support.donor)
  }
}
