
import Vue, { PropType } from 'vue'
import { Sermon } from '~/models/sermon'
import SaIcon from '~/components/_general/SaIcon.vue'
import SaImage from '~/components/_general/SaImage.vue'
import { siteSpeakerUrl } from '~/assets/ts/utils/urls'
import HorizontalScroll from '~/components/_general/HorizontalScroll.vue'

export default Vue.extend({
  name: 'HorizontalSermonSelector',
  components: { HorizontalScroll, SaImage, SaIcon },
  props: {
    currentSermonID: {
      type: String,
      default: '',
    },
    sermons: {
      type: Array as PropType<Sermon[]>,
      default() {
        return []
      },
    },
    fadeBgColor: {
      type: String,
      default: 'bg-theme',
      validator(value: string) {
        return value.includes('bg-')
      },
    },
  },
  computed: {
    hasSermons(): boolean {
      return !!(this.sermons && this.sermons.length)
    },
    selectedSermonID(): string {
      if (this.hasSermons && !this.currentSermonID) return this.sermons[0].id
      return this.currentSermonID
    },
  },
  methods: {
    siteSpeakerUrl,
    getSermonIndex(sermonID: string): number {
      if (!this.hasSermons) return 0
      return this.sermons?.findIndex((s) => s.id === sermonID)
    },
    isSelected(sermon: Sermon) {
      return sermon.id === this.selectedSermonID
    },
    sermonSelect(sermon: Sermon) {
      if (this.isSelected(sermon)) return
      const sermonId = sermon.id
      this.$emit('selectSermon', sermonId)
    },
  },
})
