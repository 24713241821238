
import Vue from 'vue'
import { favicons, images, metadata } from '~/assets/ts/utils/metadata'
import { Series } from '~/models/series'
import { Sermon } from '~/models/sermon'
import { DateFormat, PlayerMediaType, PlayerStatus } from '~/assets/ts/enums'
import {
  siteSeriesUrl,
  siteSermonUrl,
  siteShareUrl,
} from '~/assets/ts/utils/urls'
import { CustomSiteText } from '~/apiclient/apicustomsites'
import SaIcon from '~/components/_general/SaIcon.vue'
import MarkdownElement from '~/components/markdown/Element.vue'
import PlayerElement from '~/components/player/Element.vue'
import HorizontalSermonSelector from '~/components/_general/HorizontalSermonSelector.vue'
import DonationCampaignElement from '~/components/_general/DonationCampaignElement.vue'
import ShareFacebook from '~/components/share/Facebook.vue'
import ShareEmail from '~/components/share/Email.vue'
import ShareTwitter from '~/components/share/Twitter.vue'
import ShareQR from '~/components/share/QR.vue'
import ShareLink from '~/components/share/Link.vue'
import SiteCopyrightText from '~/components/site/CopyrightText.vue'
import BackgroundImage from '~/components/_general/BackgroundImage.vue'
import { PromiseAll } from '~/assets/ts/utils/misc'
import { localizeDateTime } from '~/assets/ts/utils/date'
import VaultDonateButton from '~/components/vault/DonateButton.vue'
import SaSvg from '~/components/_general/SaSvg.vue'

export default Vue.extend({
  components: {
    SaSvg,
    VaultDonateButton,
    BackgroundImage,
    SiteCopyrightText,
    ShareLink,
    ShareQR,
    ShareTwitter,
    ShareEmail,
    ShareFacebook,
    DonationCampaignElement,
    HorizontalSermonSelector,
    PlayerElement,
    MarkdownElement,
    SaIcon,
  },
  layout: 'default',
  colorMode: 'light',
  data() {
    return {
      autoplayVlogVideo: false,
      playerStatus: PlayerStatus.Static as PlayerStatus,
      showVideo: false,
      highlightedVlogSermonID: undefined as string | undefined,
      videoSrc: '',
      vlogSermonsApi: [] as Record<string, any>[],
    }
  },
  async fetch() {
    await PromiseAll([this.getCustomText(), this.getBroadcaster()])
  },
  head(this: any) {
    return metadata(this, {
      title: 'The Vault',
      favicons: favicons('vault', '#000000'),
      description: this.pageDescription,
      images: images(
        'https://media.sermonaudio.com/images/1/default/03-the-vault-new.jpg',
        true
      ),
    })
  },
  computed: {
    videoSermonID(): string {
      return '121922459171463'
    },
    videoType(): PlayerMediaType {
      return PlayerMediaType.Video
    },
    shareTitle(): string {
      return 'The Vault'
    },
    shareUrl(): string {
      return 'https://vault.sermonaudio.com'
    },
    vaultImage(): string {
      return 'https://media.sermonaudio.com/images/default/vault-still-background2.jpg'
    },
    vaultText(): Record<string, CustomSiteText> {
      return this.$store.getters.customSiteText
    },
    vlogSeries(): Series | undefined {
      if (!this.hasVlogSermons) return undefined
      return this.vlogSermons[0].series
    },
    vlogSeriesUrl(): string {
      return siteShareUrl(siteSeriesUrl(this.vlogSeries))
    },
    hasVlogSermons(): boolean {
      return !!(this.vlogSermons && this.vlogSermons.length)
    },
    vlogSermons(): Sermon[] {
      if (!this.vlogSermonsApi) return []
      return this.vlogSermonsApi.map((s: Record<string, any>) => new Sermon(s))
    },
    highlightedVlogSermon(): Sermon | undefined {
      if (!this.highlightedVlogSermonID) return undefined
      return this.vlogSermons.find((s) => s.id === this.highlightedVlogSermonID)
    },
    highlightedVlogSermonUrl(): string {
      return siteShareUrl(siteSermonUrl(this.highlightedVlogSermon))
    },
    vlogSermonIndex(): number | undefined {
      if (!this.highlightedVlogSermon) return undefined
      return this.vlogSermons.indexOf(this.highlightedVlogSermon)
    },
    nextVlogSermonIndex(): number | undefined {
      if (this.vlogSermonIndex === undefined) return undefined
      const reverse = this.$store.getters['player/autoplayNextReverse']
      const dir = reverse ? -1 : 1
      const index = this.vlogSermonIndex + dir
      if (index >= this.vlogSermons.length || index < 0) return undefined
      return index
    },
    nextVlogSermon(): Sermon | undefined {
      if (this.playerStatus !== PlayerStatus.Ended) return
      if (this.nextVlogSermonIndex === undefined) return undefined
      return this.vlogSermons[this.nextVlogSermonIndex]
    },
    highlightedVlogSermonDate(): string {
      if (!this.highlightedVlogSermon) return ''
      return localizeDateTime(
        this.highlightedVlogSermon.preachDate,
        DateFormat.Date
      )
    },
    // highlightedSermonPlaying(): boolean {
    //   return ![PlayerStatus.Static, PlayerStatus.Paused].includes(
    //     this.playerStatus
    //   )
    // },
    year(): number {
      return new Date().getFullYear()
    },
    pageDescription(): string {
      return this.customText('description')
    },
    contributeText(): string {
      return this.customText('contribute')
    },
    primaryMarkdown(): string {
      return this.customText('body')
    },
    campaignModalDescription(): string {
      return this.customText('donor-modal-desc')
    },
  },
  mounted() {
    this.loadVlogSermons()
  },
  methods: {
    async getCustomText() {
      await this.$store.dispatch('getVaultCustomText')
    },
    async getBroadcaster() {
      await this.$store.dispatch('broadcasters/fetchBroadcaster', {
        broadcasterID: 'misc',
        lite: false,
      })
    },
    customText(
      field: 'body' | 'contribute' | 'description' | 'donor-modal-desc'
    ) {
      if (!this.vaultText[field]) return ''
      return this.vaultText[field].body
    },
    async loadVlogSermons() {
      // TODO: one day make this load a lite list and just get each sermon as needed
      const { results } = await this.$apiClient.getFilteredSermonList({
        series: '147054',
        broadcasterID: 'misc',
        pageSize: 100,
        cache: false,
        requireVideo: true,
      })
      this.vlogSermonsApi = results
      if (!this.hasVlogSermons) return
      this.setHighlightedSermon(this.vlogSermons[0].id, false)
    },
    highlightedPlayerUpdated(status: PlayerStatus) {
      if (status === PlayerStatus.FetchingNext && this.nextVlogSermon) {
        this.highlightedVlogSermonID = this.nextVlogSermon.id
      }
      this.playerStatus = status
    },
    setHighlightedSermon(sermonID: string, autoplay = true) {
      this.autoplayVlogVideo = autoplay
      this.highlightedVlogSermonID = sermonID
    },
    setShowVideo(show: boolean) {
      this.showVideo = show
      this.videoSrc = !show
        ? ''
        : `https://embed.sermonaudio.com/player/v/${this.videoSermonID}?autoplay=true/`
    },
  },
})
