
import Vue from 'vue'

export default Vue.extend({
  name: 'ProgressBar',
  props: {
    /** Tailwind height class */
    height: {
      type: String,
      default: 'h-5',
    },
    goal: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      default: 0,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    percentage(): number {
      const decimal = this.value / this.goal
      return 100 * decimal
    },
  },
})
